import React from 'react'

import TextLink from 'Clutch/Atoms/TextLink'
import Svg from 'Clutch/Atoms/Svg'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import styles from './digitalAssets.module.scss'

const DigitalAssets = ({ digitalAssets }) => {
  if (!digitalAssets)
    return false

  return (
    <ErrorBoundary>
      {digitalAssets.map((digitalAsset, i) =>
        <div className={styles.digital_asset_container} key={`digital_asset_${i}`}>
          <Svg className={styles.digital_asset_icon} icon={'catalog'} size={1} />
          <TextLink
            size={0.875}
            inline
            underline
            targetBlank
            href={digitalAsset.assetType === 'Image' ?
              "https://" + (
                digitalAsset.associatedImages?.find(image => image.imageType === "Optimized")?.imageUrl ?
                  digitalAsset.associatedImages.find(image => image.imageType === "Optimized").imageUrl
                  :
                  digitalAsset.associatedDocument?.documentUrl
              )
              :
              digitalAsset.associatedDocument?.documentUrl || digitalAsset.assetName}
              data-testid={`digital_assets_textlink_${digitalAsset.displayTitle ? digitalAsset.displayTitle.slice(0, 20) : i}`}>
            {digitalAsset.displayTitle}
          </TextLink>
        </div>
      )}
    </ErrorBoundary>
  )
}

export default DigitalAssets