import React, { Fragment, useState, useEffect, useContext, useMemo, useRef } from 'react'

import { useProductPageStore } from '../../contexts/ProductPageStore';

import { TrackingContext } from 'Clutch/Contexts/TrackingContext/TrackingContext'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import VehicleFitment from './vehicleFitment/vehicleFitment'
import Shipping from './shipping/shipping'
import AddToCartForm from './atcForm/atcForm'
import NonSaleableAddToCartForm from './atcForm/nonSaleableAtcForm'
import Pricing from './pricing/pricing'
import RatingAndQuestionsOverview from './rating/rating'
import Bullets from './bullets/bullets'
import Title from './title/title'

import Divider from 'Clutch/Atoms/Divider'
import Skeleton from 'Clutch/Atoms/Skeleton'
import IndexOptions from 'Clutch/Components/IndexOptions'

import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'

import styles from './offer.module.scss'
import KitDetailsDrawerHandler from './kitDetailsDrawerHandler';
import ProductPageSuspense from '../productPageSuspense/productPageSuspense';

const Offer = () => {
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const isPurchaseable = useProductPageStore(x => x.isPurchaseable);
  const alternativeProducts = useProductPageStore(x => x.alternativeProducts);
  const bullets = useProductPageStore(x => x.bullets);
  const indexOptions = useProductPageStore(x => x.indexOptions);
  const selectIndexOption = useProductPageStore(x => x.selectIndexOption);
  const productPageId = useProductPageStore(x => x.productPageId);
  const isIndexOptionErrorState = useProductPageStore(x => x.isIndexOptionErrorState);
  const availability = useProductPageStore(x => x.availability);

  const userVin = useUserStore((x) => x.context.userVin);
  const userGuid = useUserStore((x) => x.context.userGuid);

  const { segmentReady } = useContext(TrackingContext)

  const [isWaiting, setIsWaiting] = useState(false)

  useEffect(() => {
    if (segmentReady &&
      !isPurchaseable &&
      (alternativeProducts || []).length > 0) {
      analytics.track('Product List Viewed', {
        'user_vin': `${userVin()}`,
        'web_user_guid': `${userGuid}`,
        'list_id': `pdp_offer_alts_0`,
        'category': `Alternative models available`,
        'products': (alternativeProducts || []).map((alt, i) => {
          return {
            'product_id': alt.skuBaseNumber,
            'sku': alt.skuBaseNumber,
            'name': alt.title,
            'price': alt.price,
            'position': i,
            'category': alt.segmentCategorization,
            'url': alt.productPageUrl,
            'image_url': alt.imageUrl,
            'quantity': 1,
            'brand': alt.brand,
            'availability': alt.availability,
            'rating': alt.reviewCount ? alt.rating : 0,
            'reviews': alt.reviewCount,
            'audience_affinity': getSuperMarketAffinity(alt)
          }
        })
      })
    }
  }, [isPurchaseable, segmentReady])

  const backgroundColor = LayerColorFinder(1)

  return (
    <Fragment>
      <ProductPageSuspense>
        <KitDetailsDrawerHandler/>
      </ProductPageSuspense>
      <Title/>
      <RatingAndQuestionsOverview
        skuBaseNumber={skuBaseNumber}/>
      <Bullets bullets={bullets} />
      {indexOptions &&
        indexOptions.length > 0 &&
        bullets &&
        bullets.length > 0 ?
        <Divider key={'ix-option-divider'} className={styles.divider} /> : false}
      {useMemo(() =>
        <IndexOptions
          indexOptions={indexOptions}
          selectIndexOption={selectIndexOption}
          productPageId={productPageId}
          isIndexOptionErrorState={isIndexOptionErrorState}
          buttonCuttoffValue={10} />
        , [indexOptions, selectIndexOption, productPageId, isIndexOptionErrorState])
      }

      {indexOptions && indexOptions.length > 0 ?
        <Divider key={'ix-option-bottom'} className={styles.pdp_offer_option} /> : false}
      <Pricing
        skuVariantNumber={skuVariantNumber}
        availability={availability}
        isPurchaseable={isPurchaseable}
        alternativeProducts={alternativeProducts}
        backgroundColor={backgroundColor}
      />
      <br />
      {isPurchaseable ?
        <Fragment>
          <AddToCartForm
            isWaiting={isWaiting}
            setIsWaiting={setIsWaiting}
          />          
          <VehicleFitment />
        </Fragment> :
        <NonSaleableAddToCartForm
          skuVariantNumber={skuVariantNumber}
          skuBaseNumber={skuBaseNumber} />
      }
      <Shipping
        skuVariant={skuVariantNumber}
        availability={availability}
        backgroundColor={backgroundColor}
        isPurchaseable={isPurchaseable}/>
    </Fragment>
  )
}

export default Offer