import React from 'react'

import Typography from 'Clutch/Atoms/Typography'
import Button from 'Clutch/Molecules/Button'
import Card from 'Clutch/Atoms/Card'
import Svg from 'Clutch/Atoms/Svg'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import ContactUsCard from "Clutch/Organisms/ContactUsCard/ContactUsCard";
import styles from './contact.module.scss'

const Contact = ({ }) => {

  return (
    <div className={styles.contact_card}>
      <ContactUsCard />
    </div>
  )
}

export default Contact