import React, { useContext } from "react";

import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";

import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";

import styles from "./SelectFitment.module.scss";
import { fitmentDisplayTypes } from "../../determineFitmentType";

import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import useFeatureFlag from "Clutch/Hooks/useFeatureFlag";

const SelectFitment = ({ type }) => {
  const openAddOrSelectCustomerProjectExperience = useVehicleStore(
    (state) => state.openAddOrSelectCustomerProjectExperience
  );

  const { dispatch: navigationDispatch } = useContext(NavigationContext);

  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );``

  let fitmentHeader = "Select a vehicle to verify Fitment";
  let fitmentLink = (
    <TextLink
      className={styles.vehicle_fitment_link}
      size={0.875}
      chevron={"right"}
      onClick={() =>
        customerProjectDrawerEnabled
          ? navigationDispatch({
              type: "setCustomerProjectDrawerState",
              payload: { garageState: { isCustomerProjectDrawerOpen: true } },
            })
          : openAddOrSelectCustomerProjectExperience()
      }
      data-testid={`pdp-select-vehicle-for-fitment`}
    >
      Select Vehicle
    </TextLink>
  );
  if (type === fitmentDisplayTypes.selectEngine) {
    fitmentHeader = "Select an engine to verify Fitment";
    fitmentLink = (
      <TextLink
        className={styles.vehicle_fitment_link}
        size={0.875}
        chevron={"right"}
        onClick={() =>
          customerProjectDrawerEnabled
            ? navigationDispatch({
                type: "setCustomerProjectDrawerState",
                payload: { garageState: { isCustomerProjectDrawerOpen: true } },
              })
            : openAddOrSelectCustomerProjectExperience()
        }
        data-testid={`pdp-select-engine-for-fitment`}
      >
        Select Engine
      </TextLink>
    );
  } else if (type === fitmentDisplayTypes.selectVehicleAndEngine) {
    fitmentHeader = "Select a vehicle and engine to verify Fitment";
    fitmentLink = (
      <>
        <div className={styles.vehicle_and_engine_link_container}>
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            chevron={"right"}
            onClick={() =>
              customerProjectDrawerEnabled
                ? navigationDispatch({
                    type: "setCustomerProjectDrawerState",
                    payload: {
                      garageState: { isCustomerProjectDrawerOpen: true },
                    },
                  })
                : openAddOrSelectCustomerProjectExperience()
            }
            data-testid={`pdp-select-vehicle-for-fitment`}
          >
            Select Vehicle
          </TextLink>
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            chevron={"right"}
            onClick={() =>
              customerProjectDrawerEnabled
                ? navigationDispatch({
                    type: "setCustomerProjectDrawerState",
                    payload: {
                      garageState: { isCustomerProjectDrawerOpen: true },
                    },
                  })
                : openAddOrSelectCustomerProjectExperience()
            }
            data-testid={`pdp-select-engine-for-fitment`}
          >
            Select Engine
          </TextLink>
        </div>
      </>
    );
  }

  return (
    <ErrorBoundary>
      <Area
        brand={"secondary"}
        className={styles.vehicle_fitment}
        icon={"universal_fit"}
        centerIcon={false}
      >
        <Typography className={styles.vehicle_fitment_header} font={"bold"}>
          {fitmentHeader}
        </Typography>
        {fitmentLink}
      </Area>
    </ErrorBoundary>
  );
};

export default SelectFitment;
