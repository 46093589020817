
import { useEffect, useState } from "react";
import { useProductPageStore } from "../../../contexts/ProductPageStore";
import RestRequest from "Utilities/RestClient/RestClient";
import Typography from 'Clutch/Atoms/Typography'
import styles from './loyaltypointspreview.module.scss'
import Svg from 'Clutch/Atoms/Svg'
import {
  IsUserBot,
} from "Clutch/Utilities/CookieUtilties/UserCookieUtilities";
import { useInView } from "react-intersection-observer";

const LoyaltyPointsPreview = () => {

  const price = useProductPageStore(x => x.price);
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const quantity = useProductPageStore(x => x.quantityRequested);
  const isPurchaseable = useProductPageStore(x => x.isPurchaseable);

  const [preview, setPreview] = useState(null);


  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '100px 0px',
  });

  const fetchPointsPreview = async () => {
    const body = {
      lineItems: [
        {
          sku: skuVariantNumber?.length ? skuVariantNumber : skuBaseNumber,
          price: `${price}`,
          quantity
        }
      ]
    }
    var result = await RestRequest(`/api/customer/loyalty/pointspreview`, null, body);
    if (result.shouldShow) {
      console.log(`${result.segmentEvent.name}: ${JSON.stringify(result.segmentEvent.properties)}`)
      analytics.track(result.segmentEvent.name, result.segmentEvent.properties)

    }
    setPreview(result);
  }

  useEffect(() => {
    if (inView && skuBaseNumber && price && quantity && !IsUserBot() && isPurchaseable) {
      fetchPointsPreview();
    }
  }, [skuBaseNumber, price, skuVariantNumber, quantity, inView, isPurchaseable])

  if (!isPurchaseable) {
    return null;
  }

  if (!inView) {
    return <span ref={ref}></span>
  }

  if (!preview || !preview.shouldShow) {
    return null;
  }

  return <div className={styles.container}>
    <Typography className={styles.text} size={0.875}><Svg size={1} icon={`rewards`} brand={`primary`} /><span> You will earn <Typography as={`span`} font={`bold`} size={0.875}>{preview.potentialPoints} points ({preview.totalDollarValue.string})</Typography> to spend on future orders.</span></Typography>
  </div>


}

export default LoyaltyPointsPreview