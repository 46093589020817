import React, { useState, useEffect, useContext } from 'react'
import ClassNames from 'classnames'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary/ErrorBoundary'
import Button from 'Clutch/Molecules/Button'

import { useProductPageStore } from '../../contexts/ProductPageStore'
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext'

import { GetUserGuid } from 'Clutch/Utilities/CookieUtilties/UserCookieUtilities'
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import styles from './mobileStickyAtc.module.scss'

const MobileStickyFooter = ({ triggerId, testing }) => {
  const [isVisible, setVisibility] = useState(testing ? false : null);

  const handleScroll = () => {
    var triggerElement = document.getElementById(triggerId);

    if (triggerElement) {
      var triggerOffset = triggerElement.offsetTop;
      const position = window.pageYOffset;

      if (triggerOffset < position) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const superMarketAffinity = useProductPageStore(x => x.superMarketAffinity)
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const quantityRequested = useProductPageStore(x => x.quantityRequested);
  const title = useProductPageStore(x => x.title);
  const images = useProductPageStore(x => x.images);
  const segmentCategorization = useProductPageStore(x => x.segmentCategorization);
  const brand = useProductPageStore(x => x.brand);
  const retailLowPrice = useProductPageStore(x => x.retailLowPrice);
  const productPageUrl = useProductPageStore(x => x.productPageUrl);
  const isPurchaseable = useProductPageStore(x => x.isPurchaseable);
  const availabilityEnum = useProductPageStore(x => x.availabilityEnum);
  const reviewCount = useProductPageStore(x => x.reviewCount);
  const rating = useProductPageStore(x => x.rating);
  const isIndexOptionKit = useProductPageStore(x => x.isIndexOptionKit);
  const errorIndexOptions = useProductPageStore(x => x.errorIndexOptions);
  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  const item = {
    skuVariantNumber: skuVariantNumber,
    skuBaseNumber: skuBaseNumber,
    quantityRequested: parseInt(quantityRequested),
    title: title,
    images: images,
    segmentCategory: segmentCategorization,
    brand: brand?.brandName,
    price: retailLowPrice,
    url: productPageUrl,
  }

  if (isVisible == null)
    return false;

  return (
    <ErrorBoundary>
      {isPurchaseable ?
        <div className={styles.atc_container} id={`pdp_atc_sticky_container`}>
          <Button
            inline
            intent={'positive'}
            size={'small'}
            icon={'add_to_cart'}
            className={ClassNames(styles.atc_button, !isVisible ? styles.slide : false)}
            data-testid={'mobile_sticky_atc_btn'}
            segmentEvent={{
              event: 'Product Add Started',
              properties: {
                cart_id: GetUserGuid(),
                product_id: skuBaseNumber,
                sku: skuBaseNumber,
                name: title,
                variant: skuVariantNumber,
                quantity: quantityRequested,
                brand: brand ? brand.brandName || '' : '',
                category: segmentCategorization,
                price: retailLowPrice,
                url: productPageUrl,
                image_url: (images || []).length ? `https:${images[0]}` : "",
                availability: availabilityEnum,
                rating: reviewCount ? rating : 0,
                reviews: reviewCount,
                audience_affinity: getSuperMarketAffinity({superMarketAffinity})
              }
            }}
            onClick={
              (e) => {
                e.preventDefault()
                if (skuVariantNumber) {
                  isIndexOptionKit
                    ? addToCartExperienceContext.showKitDetailsDrawer()
                    : addToCartExperienceContext.addToCart([item])
                } else {
                  errorIndexOptions()
                }
              }
            } />
        </div>
        :
        false
      }
    </ErrorBoundary>
  )
}

export default MobileStickyFooter