import Typography from "Clutch/Atoms/Typography";
import styles from "../fitments.module.scss"

const VehicleDisplayText = ({ vehicle }) => {
  let VehicleTextComponent = null;
  const isEnginePresent = vehicle.engineDisplayName;

  if (vehicle.raceTypeId) {
    VehicleTextComponent = (
      <Typography as={"span"} inline size={0.875} font={"bold"}>
        {vehicle.raceName}
      </Typography>
    );
  } else if (vehicle.vehicleBaseId) {
    VehicleTextComponent = (
      <Typography as={"span"} inline size={0.875} font={"bold"}>
        {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
      </Typography>
    );
  }

  if (isEnginePresent) {
    if (VehicleTextComponent)
      VehicleTextComponent = (
        <>
          {VehicleTextComponent}
          {" with a "}
        </>
      );

    VehicleTextComponent = (
      <>
        {VehicleTextComponent}
        <Typography as={"span"} inline size={0.875} font={"bold"}>
          {vehicle.engineDisplayName}
        </Typography>
      </>
    );
  }

  return <Typography as={"span"} size={0.875} className={styles.vehicle_text}>{VehicleTextComponent}</Typography>;
};

export default VehicleDisplayText;
