async function getCustomerImages(skuBase) {
  try {
    const query = `query GetCustomerImagesForProduct($skuBase : String!){
    customerImagesForProduct(skuBase: $skuBase) {
      imageUrls
    }
  }`

    const variables = {
      skuBase: skuBase,
    }

    const body = JSON.stringify({
      query,
      variables
    })

    const res = await fetch('/graphql/products',
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      })

    const data = await res.json()
    return data.data.customerImagesForProduct;
  } catch (err) {
    console.error(err);
  }
}

export default getCustomerImages;