import Typography from "Clutch/Atoms/Typography";
import VehicleDisplayText from "../vehicleDisplayText/vehicleDisplayText";

const partialFitTypes = {
  fitsVehicleButNotEngine: "FitsVehicleButNotEngine",
  fitsEngineButNotVehicle: "FitsEngineButNotVehicle",
  fitsRaceTypeButNotEngine: "FitsRaceTypeButNotEngine",
  fitsEngineButNotRaceType: "FitsEngineButNotRaceType",
};

const DoesNotFitVehicleDisplayText = ({ vehicle, partialFit }) => {
  const vehicleDisplayText = vehicle.vehicleBaseId
    ? `${vehicle.year} ${vehicle.model}`
    : vehicle.raceName;
  const engineDisplayText = vehicle.engineDisplayName;

  if (
    partialFit === partialFitTypes.fitsVehicleButNotEngine ||
    partialFit === partialFitTypes.fitsRaceTypeButNotEngine
  ) {
    return (
      <Typography size={0.875}>
        This part fits your{" "}
        <Typography font={"bold"} size={0.875} inline>
          {vehicleDisplayText}
        </Typography>{" "}
        but does not fit your{" "}
        <Typography font={"bold"} size={0.875} inline>
          {engineDisplayText}
        </Typography>
      </Typography>
    );
  } else if (
    partialFit === partialFitTypes.fitsEngineButNotVehicle ||
    partialFit === partialFitTypes.fitsEngineButNotRaceType
  ) {
    return (
      <Typography size={0.875}>
        This part fits your{" "}
        <Typography font={"bold"} size={0.875} inline>
          {engineDisplayText}
        </Typography>{" "}
        but does not fit your{" "}
        <Typography font={"bold"} size={0.875} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
  }

  return <VehicleDisplayText vehicle={vehicle} />;
};

export default DoesNotFitVehicleDisplayText;
