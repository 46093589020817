import React, { useContext } from "react";

import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";

import Drawer from "Clutch/Molecules/NewDrawer/Drawer";
import Divider from "Clutch/Atoms/Divider";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import Typography from "Clutch/Atoms/Typography";
import Svg from "Clutch/Atoms/Svg";
import Button from "Clutch/Molecules/Button";
import Panel from "Clutch/Atoms/Panel";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";

import styles from "./fitmentDrawer.module.scss";
import { useProductPageStore } from "../../../contexts/ProductPageStore";
import FitmentSection from "./fitmentSection/fitmentSection";

const FitmentDrawer = ({}) => {
  const customerProjects = useVehicleStore((x) => x.context.customerProjects);
  const navigationContext = useContext(NavigationContext);
  const fitment = useProductPageStore(x => x.fitment);
  const isFitmentDrawerOpen = useProductPageStore(x => x.isFitmentDrawerOpen);
  const hideFitmentDrawer = useProductPageStore(x => x.hideFitmentDrawer);

  if ((customerProjects || []).length < 1) return false;

  const vehicleBaseProjectIds = customerProjects
    .filter((customerVehicle) => customerVehicle.vehicleBaseId)
    .map((customerVehicle) => customerVehicle.projectId);
  const raceTypeProjectIds = customerProjects
    .filter((customerVehicle) => customerVehicle.raceTypeId)
    .map((customerVehicle) => customerVehicle.projectId);
  const engineProjectIds = customerProjects
    .filter(
      (customerVehicle) =>
        customerVehicle.engineDisplayName &&
        !customerVehicle.raceTypeId &&
        !customerVehicle.vehicleBaseId
    )
    .map((customerVehicle) => customerVehicle.projectId);

  let vehicleBaseFitment = [];
  let raceTypeFitment = [];
  let engineFitment = [];

  if (fitment && fitment.length) {
    vehicleBaseFitment = fitment.filter((f) =>
      vehicleBaseProjectIds.includes(f.key)
    );
    raceTypeFitment = fitment.filter((f) => raceTypeProjectIds.includes(f.key));
    engineFitment = fitment.filter((f) => engineProjectIds.includes(f.key));
  }

  const footer = <>
            <Button
            intent={"action"}
            segmentEvent={createClickedSegmentEvent("AddNewVehicle")}
            text={"Add New Vehicle"}
            size={"small"}
            className={styles.fitment_drawer_button}
            fill
            onClick={() => {
              hideFitmentDrawer();
              navigationContext.setYmmModal(true);
            }}
          />
          <Button
            intent={"action"}
            segmentEvent={createClickedSegmentEvent("ManageGarage")}
            text={"Manage Garage"}
            size={"small"}
            className={styles.fitment_drawer_button}
            invert
            fill
            href={"/CustomerVehicle"}
          />
  </>

  return (
    <Drawer
      location={"right"}
      width={640}
      isOpen={!!isFitmentDrawerOpen}
      onClose={() => hideFitmentDrawer()}
      footer={footer}
      title={'Your Vehicles'}
      portalId={'your_vehicles_drawer'}
    >

          {raceTypeFitment.map((vehicle) => {
            const matchingVehicle =
              (customerProjects || []).filter(
                (customerVehicle) =>
                  customerVehicle && customerVehicle.projectId === vehicle.key
              )[0] || {};
            const hasEngine = matchingVehicle.engineDisplayName;

            return (
              <FitmentSection key={vehicle.key} doesFit={vehicle.value.fits} hasAddonEngine={hasEngine} mainDisplayName={matchingVehicle.raceName} engineAddonDisplayName={matchingVehicle.engineDisplayName}/>
            );
          })}
          {(vehicleBaseFitment || []).map((vehicle) => {
            let matchingVehicle = (customerProjects || []).filter(
              (customerVehicle) =>
                customerVehicle && customerVehicle.projectId === vehicle.key
            )[0];
            matchingVehicle = matchingVehicle || {};
            const hasEngine = matchingVehicle.engineDisplayName;

            return (
              <FitmentSection key={vehicle.key} doesFit={vehicle.value.fits} hasAddonEngine={hasEngine} mainDisplayName= {`${matchingVehicle.year} ${matchingVehicle.make} ${matchingVehicle.model}`} engineAddonDisplayName={matchingVehicle.engineDisplayName}/>
            );
          })}
          {(engineFitment || []).map((vehicle) => {
            let matchingVehicle = (customerProjects || []).filter(
              (customerVehicle) =>
                customerVehicle && customerVehicle.projectId === vehicle.key
            )[0];
            matchingVehicle = matchingVehicle || {};
            return  <FitmentSection key={vehicle.key} doesFit={vehicle.value.fits} hasAddonEngine={false} mainDisplayName={matchingVehicle.engineDisplayName}/>
          })}

    </Drawer>
  );
};

export default FitmentDrawer;
