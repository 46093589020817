import React from 'react';
import Typography from 'Clutch/Atoms/Typography';
import TextLink from 'Clutch/Atoms/TextLink';
import Rating from 'Clutch/Atoms/Rating';
import styles from './rating.module.scss'
import { useProductPageStore } from '../../../contexts/ProductPageStore';
import {ScrollLink} from "../../../components/ScrollLink";

const RatingAndQuestionsOverview = ({ skuBaseNumber }) => {

    const summarizedReview = useProductPageStore(x => x.summarizedReview);
    const powerReviewsMerchantId = useProductPageStore(x => x.powerReviewsMerchantId);
    const powerReviewsMerchantGroupId = useProductPageStore(x => x.powerReviewsMerchantGroupId);
    const numberOfQuestions = useProductPageStore(x => x.numberOfQuestions);
    const scrollTo = useProductPageStore(x => x.scrollTo);
    const reviewCount = useProductPageStore(x => x.reviewCount);
    const ratingValue = useProductPageStore(x => x.rating);

  return (
    <div>
      <Rating ratingValue={ratingValue} />
      <span className={styles.rating_spacer}></span>
      
      {reviewCount ?
        <ScrollLink
          id="pdp_ratings"
          accordionName="RatingsAndReviews"
          size={0.75}
          tone={'subtle'}
          inline
          underline
          shouldOpenAccordion={!summarizedReview}
        >
          {reviewCount + ` ${reviewCount === 1 ? 'Review' : 'Reviews'}`}
        </ScrollLink> :
        <TextLink size={0.75} tone={'subtle'} inline underline
          href={`/ReviewSubmission?` +
            `pr_page_id=${skuBaseNumber}` +
            `&pr_merchant_id=${powerReviewsMerchantId}` +
            `&pr_merchant_group_id=${powerReviewsMerchantGroupId}`}>
          Write a Review
        </TextLink>
      }

      <Typography size={0.75} tone={'subtle'} inline>
        {' | '}
      </Typography>

      {numberOfQuestions ?

          <ScrollLink
              id="pdp_qna"
              accordionName="QuestionsAndAnswers"
              size={0.75}
              tone={'subtle'}
              inline
              underline
              shouldOpenAccordion={true}
          >
              {numberOfQuestions + ` ${numberOfQuestions === 1 ? 'Question' : 'Questions'}`}
          </ScrollLink> :
          <ScrollLink
              id="pdp_qna"
              accordionName="QuestionsAndAnswers"
              size={0.75}
              tone={'subtle'}
              inline
              underline
              shouldOpenAccordion={true}
          >
              Ask a Question
          </ScrollLink>
      }
    </div>
  );
};

export default RatingAndQuestionsOverview;
