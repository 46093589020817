import React from 'react'
import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'
import Svg from 'Clutch/Atoms/Svg'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import {useProductPageStore} from '../../../contexts/ProductPageStore'
import styled from 'styled-components'
import styles from './description.module.scss'
import {ScrollLink} from "../../../components/ScrollLink";

const Description = ({ toggleAccordion, isKit, showWhatsInTheKitDrawer, subtitle, bullets, hasRebates }) => {
  const scrollTo = useProductPageStore(x => x.scrollTo);
  const calloutBorderColor = LayerColorFinder(3)

  return (
    <ErrorBoundary>
      <Typography size={0.875} font={'bold'} tone={'subtle'} className={styles.pdp_summary_subtitle}>
        {subtitle}
      </Typography>
      <ul className={styles.pdp_summary_bullets}>
        {bullets && bullets.length ?
          bullets.map((bullet, i) =>
            <Typography as={'li'} key={`bullet-summary-${i}:${bullet.substr(0, 20)}`} className={styles.pdp_summary_bullet} size={0.875}>
              {bullet}
            </Typography>
          ) : false}
      </ul>
      {isKit || hasRebates ?
        <div className={styles.pdp_summary_callouts}>
          {isKit ?
            <StyledCallout className={styles.pdp_summary_callout} calloutBorderColor={calloutBorderColor}>
              <Svg className={styles.pdp_summary_callout_icon} icon={'kit'} size={1.25} />
              <div className={styles.pdp_summary_callout_text}>
                <Typography className={styles.pdp_summary_callout_text} inline size={0.875} font={'bold'} tone={'subtle'}>Comes as a Kit</Typography>
                <TextLink intent={'action'} className={styles.pdp_summary_callout_text} chevron={'right'} size={0.75} font={'bold'}
                  onClick={() => showWhatsInTheKitDrawer()}>
                  View Kit Components
                </TextLink>
              </div>
            </StyledCallout> : false}
          {hasRebates ?
            <StyledCallout className={styles.pdp_summary_callout} calloutBorderColor={calloutBorderColor}>
              <Svg className={styles.pdp_summary_callout_icon} icon={'dollar'} size={1.25} />
              <div className={styles.pdp_summary_callout_text}>
                <Typography className={styles.pdp_summary_callout_text} inline size={0.875} font={'bold'} tone={'subtle'}>Rebates Available</Typography>
                  <ScrollLink
                      id="pdp_details_rebates"
                      accordionName="Details"
                      className={styles.pdp_summary_callout_text}
                      size={0.75}
                      intent={'action'}
                  >
                      See rebate details
                  </ScrollLink>
              </div>
            </StyledCallout> : false}
        </div> : false}

        <ScrollLink
            id="pdp_details"
            accordionName="Details"
            size={0.875}
            intent={'action'}
            inline
            className={styles.see_more}
        >
            See more details
        </ScrollLink>

    </ErrorBoundary>
  )
}

const StyledCallout = styled.span`
  border-color: ${props => props.calloutBorderColor}
`

export default Description