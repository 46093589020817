
export async function sendFitmentFeedbackToZendesk(input) {

  const variables = {
    input: input
  }

  const query = `
    query sendFitmentComplaintToZendesk ($input: EngineFitmentComplaintInput){
      sendFitmentComplaintToZendesk (input: $input) {
        success
        message
      }
    }
  `

  const body = JSON.stringify({
    query,
    variables
  })

  const res = await fetch('/graphql/products',
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body 
    })
  const data = await res.json()

  return data?.data?.sendFitmentComplaintToZendesk?.success;
}