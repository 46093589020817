import React from "react";
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";
import Gutters from "Clutch/Atoms/Gutters";

const SummaryRecommendations = ({keywords}) => {
  return (
    <Gutters>
      <RecommendationSection section={"PDP_Top"} numberOfSkeletons={5} displayType={"Carousel"} spacingSize={'None'} keywords={keywords} />
    </Gutters>
  );
};

export default SummaryRecommendations;
