import VehicleDisplayText from "../vehicleDisplayText/vehicleDisplayText";

const OtherVehiclesThatFit = ({
  selectedVehicle,
  vehicleBasesThatFit,
  raceTypesThatFit,
  enginesThatFit,
  customerProjects,
}) => {
  return (
    <>
      {(vehicleBasesThatFit || []).map((vehicle, i) => {
        const matchingProject = customerProjects?.find(
          (customerVehicle) => customerVehicle.projectId == vehicle.key
        );

        if (!matchingProject) return false;
        if (selectedVehicle.projectId === matchingProject.projectId)
          return false;

        return <VehicleDisplayText key={`vehicle_display_base_key_${matchingProject.projectId}`} vehicle={matchingProject} />;
      })}
      {(raceTypesThatFit || []).map((vehicle, i) => {
        const matchingProject = customerProjects?.find(
          (customerVehicle) => customerVehicle.projectId == vehicle.key
        );

        if (!matchingProject) return false;
        if (selectedVehicle.projectId === matchingProject.projectId)
          return false;

        return <VehicleDisplayText key={`vehicle_display_race_key_${matchingProject.projectId}`} vehicle={matchingProject} />;
      })}
      {(enginesThatFit || []).map((vehicle, i) => {
        const matchingProject = customerProjects?.find(
          (customerVehicle) => customerVehicle.projectId == vehicle.key
        );

        if (!matchingProject) return false;
        if (selectedVehicle.projectId === matchingProject.projectId)
          return false;

        return <VehicleDisplayText key={`vehicle_display_engine_key_${matchingProject.projectId}`} vehicle={matchingProject} />;
      })}
    </>
  );
};

export default OtherVehiclesThatFit;
