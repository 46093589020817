import React from "react";

import Divider from "Clutch/Atoms/Divider";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import Typography from "Clutch/Atoms/Typography";
import Svg from "Clutch/Atoms/Svg";

import styles from "./fitmentSection.module.scss";

const FitmentSection = ({
  doesFit,
  hasAddonEngine,
  mainDisplayName,
  engineAddonDisplayName,
}) => {
  return (
    <ErrorBoundary>
      <div className={styles.fitment}>
        <div className={styles.fitment_text}>
          <Typography size={1} inline>
            {doesFit ? "Fits your" : "May not fit your"}
          </Typography>
          <Typography
            size={1}
            inline
            font={"bold"}
            className={styles.fitment_vehicle_text}
          >
            {mainDisplayName}
          </Typography>
          {hasAddonEngine && (
            <>
              <Typography
                size={1}
                inline
                className={styles.fitment_vehicle_text}
              >
                {" with a "}
              </Typography>
              <Typography
                size={1}
                inline
                font={"bold"}
                className={styles.fitment_vehicle_text}
              >
                {engineAddonDisplayName}
              </Typography>
            </>
          )}
        </div>
        <Svg
          icon={doesFit ? "check" : "warning"}
          intent={doesFit ? "positive" : "negative"}
          size={1.25}
          className={styles.fitment_svg}
        />
      </div>
      <Divider />
    </ErrorBoundary>
  );
};

export default FitmentSection;
