import React, { useMemo } from 'react'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'

import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'

import styled from 'styled-components'
import styles from './table.module.scss'

const TableRow = ({ title, value, requiresVariantSelection, indexOptions }) => {

  return (
    <ErrorBoundary>
      <Typography as={'td'} className={`${styles.spec_title}`} data-testid={`spec_title_${title}`} font={'bold'} size={0.875}>
        {title}
      </Typography>
      {requiresVariantSelection ? (
        <TextLink as={'td'} className={`${styles.spec_value}`} data-testid={`spec_value_${title}`} tone={'subtle'} size={0.875} scrollTo={'pdp_offer'} >
          {`Select 
            ${indexOptions.length > 1 ?
              indexOptions.map(x => x).slice(0, -1).join(', ')
              + ' and '
              + indexOptions.map(x => x)[indexOptions.length - 1]
              :
              indexOptions[0]
            } to see`}
        </TextLink>
      ) : (
        <SpecValue title={title} value={value} />
      )}
    </ErrorBoundary>
  )
};
const SpecValue = ({ title, value }) => {
  if (title == "CARB EO#") {
    return (
      <TextLink
        size={0.875}
        className={styles.spec_value}
        data-testid={`spec_value_${title}`}
        underline
        targetBlank
        href={`https://ww2.arb.ca.gov/sites/default/files/aftermarket/devices/eo/${value.toLowerCase()}.pdf`}>
        {value}
      </TextLink>
    )
  }
  return (
    <Typography as={'td'} data-testid={`spec_value_${title}`} className={styles.spec_value} size={0.875}>
      {value}
    </Typography>
  )
}

const Table = ({ specs, indexOptions }) => {
  const backgroundColor = LayerColorFinder(1);
  return (
    <ErrorBoundary>
      {/* <table className={styles.specs}> */}
      <tbody>
        {specs
          .sort((a, b) => {
            return parseInt(a.ordinality) - parseInt(b.ordinality);
          })
          .map((item, i) =>
            <ErrorBoundary key={`spec_acc_${item.title}_${i}`}>
              {i % 2 == 0 ?
                <tr>
                  <TableRow title={item.title} value={item.value} requiresVariantSelection={item.requiresVariantSelection} indexOptions={indexOptions} />
                </tr> :
                <ZebraStripped backgroundColor={backgroundColor}>
                  <TableRow title={item.title} value={item.value} requiresVariantSelection={item.requiresVariantSelection} indexOptions={indexOptions} />
                </ZebraStripped>
              }
            </ErrorBoundary>
          )}
      </tbody>
      {/* </table> */}
    </ErrorBoundary>
  );
}

const ZebraStripped = styled.tr`
  background-color: ${props => props.backgroundColor};
`


export default Table