import React from "react";
import Gutters from 'Clutch/Atoms/Gutters'
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";

const BottomRecommendations = ({keywords}) => {
  return (
    <Gutters>
      <RecommendationSection extendGuttersForCarousel section={"PDP_Bottom"} numberOfSkeletons={5} numberOfSkeletonSections={3} displayType={"Carousel"} spacingSize={'XXLG'} keywords={keywords} />
    </Gutters>
  );
};

export default BottomRecommendations;
