import React from 'react'
import Image from 'next/image'

import { useProductPageStore } from '../../contexts/ProductPageStore';

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Typography from 'Clutch/Atoms/Typography'
import Divider from 'Clutch/Atoms/Divider'

import Drawer from 'Clutch/Molecules/NewDrawer/Drawer'

import styles from './inStorePickupDrawer.module.scss'

const InStorePickupDrawer = ({ }) => {
  const isInStorePickupDrawerOpen = useProductPageStore(x => x.isInStorePickupDrawerOpen);
  const freeShippingAndAvailability = useProductPageStore(x => x.freeShippingAndAvailability);
  const hideInStorePickupDrawer = useProductPageStore(x => x.hideInStorePickupDrawer);

  return (
    <Drawer
      location={'right'}
      isOpen={isInStorePickupDrawerOpen}
      onClose={() => hideInStorePickupDrawer()}
      title={'Store Pickup Availability'}
      portalId={"isp_availability_drawer"}
    >
      {freeShippingAndAvailability && (freeShippingAndAvailability.warehouseAvailability || []).map(warehouse =>
        <ErrorBoundary key={`warehouse_${warehouse.warehouseId}`}>
          {
            warehouse.warehouseId === "SMI-LNK" ?
              <>
                {
                  warehouse.availability === 'InStock' ?
                    warehouse.availabilityString === 'Limited qty' ?
                      <Typography size={1} font={'heading'} intent={'warning'} className={styles.availability} data-testid={`${warehouse.warehouseId}_limited`}>
                        Limited Quantity
                      </Typography>
                      :
                      <Typography size={1} font={'heading'} intent={'positive'} className={styles.availability} data-testid={`${warehouse.warehouseId}_available`}>
                        Available
                      </Typography>
                    :
                    <Typography size={1} font={'heading'} tone={'subtle'} className={styles.availability} data-testid={`${warehouse.warehouseId}_unavailable`}>
                      Unavailable
                    </Typography>
                }
                <Typography size={1} font={'heading'} className={styles.location_header}>Lincoln, NE</Typography>
                <Typography size={0.875} font={'heading'} tone={'subtle'}>340 Victory Ln</Typography>
                <Typography size={0.875} font={'heading'} tone={'subtle'}>Lincoln, NE 68528</Typography>
                <Divider className={styles.divider} />
              </> : false
          }
          {
            warehouse.warehouseId === "SMI-PHX" ?
              <>
                {
                  warehouse.availability === 'InStock' ?
                    warehouse.availabilityString === 'Limited qty' ?
                      <Typography size={1} font={'heading'} intent={'warning'} className={styles.availability} data-testid={`${warehouse.warehouseId}_limited`}>
                        Limited Quantity
                      </Typography>
                      :
                      <Typography size={1} font={'heading'} intent={'positive'} className={styles.availability} data-testid={`${warehouse.warehouseId}_available`}>
                        Available
                      </Typography>
                    :
                    <Typography size={1} font={'heading'} tone={'subtle'} className={styles.availability} data-testid={`${warehouse.warehouseId}_unavailable`}>
                      Unavailable
                    </Typography>
                }
                <Typography size={1} font={'heading'} className={styles.location_header}>Tolleson, AZ</Typography>
                <Typography size={0.875} font={'heading'} tone={'subtle'}>8701 W McDowell Rd</Typography>
                <Typography size={0.875} font={'heading'} tone={'subtle'}>Tolleson, AZ 85353</Typography>
                <Divider className={styles.divider} />
              </> : false
          }
        </ErrorBoundary>
      )}
    </Drawer>
  )
}

export default InStorePickupDrawer