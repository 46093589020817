import React, { useEffect, useContext } from "react";
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import { useInView } from "react-intersection-observer";
import { useRecommendationStore } from "Clutch/Stores/RecommendationStore/RecommendationStore";

const KitRecommendations = ({ testData, keywords }) => {
  const section = "PDP_Kits";
  const setRecommendationSectionInView = useRecommendationStore(x => x.setRecommendationSectionInView)

  // todo this is all going to be baked into the
  // RecommendationSection component once we're able to use that
  // so this will eventually go away!!!
  // in fact it might already be ready to go, but I don't remember for sure...
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });
  useEffect(() => {
    if (inView) {
      setRecommendationSectionInView(section);
    }
  }, [inView]);


  return (
    <ErrorBoundary>
      <RecommendationSection section={section} displayType={"Vertical"} numberOfSkeletons={1} testData={testData} spacingSize={'None'} keywords={keywords} isCard useSubtitle useDivider useItemCount />
    </ErrorBoundary>
  );
};

export default KitRecommendations;
