import React, { useContext, useState, useEffect } from 'react'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";
import { RecommendationProvider } from "Clutch/Stores/RecommendationStore/RecommendationStore"
import { useProductPageStore } from '../../contexts/ProductPageStore';
import { useRecommendationStore, useRecommendationSection } from "Clutch/Stores/RecommendationStore/RecommendationStore";

import styles from './compare.module.scss'

const ProductCompare = ({ stickyHeaderRef }) => {

  const compareEnabled = useFeatureFlag('PDP_Compare_Tool')
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber)
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber)

  if (!!!compareEnabled)
    return false

  //adding these guards for Carson Smith, need to wire up blacklist recommendations for compare tool
  if (skuBaseNumber == "9105415")
    return false

  if (skuBaseNumber == "9101034")
    return false

  return (
    <div ref={stickyHeaderRef}>
      <ErrorBoundary>
      <RecommendationProvider
          sections={['PDP_Compare']}
          skuBaseNumbers={[skuBaseNumber]}
          skuVariantNumbers={skuVariantNumber ? [skuVariantNumber] : undefined}>
        <div className={styles.product_compare_card}>
          <InnerCompare section={'PDP_Compare'}/>
        </div>
        </RecommendationProvider>
      </ErrorBoundary>
    </div>
  )
}

const InnerCompare = ({sectionType}) => {

  const isLoading = useRecommendationStore(x => x.isLoading)
  const section = useRecommendationSection(sectionType)

  const setIsCompareToolPresent = useProductPageStore(x => x.setIsCompareToolPresent)
  
    useEffect(() => {
    if(isLoading || !isLoading && section) {
      setIsCompareToolPresent(true)
    } else if (!isLoading && !section) {
      setIsCompareToolPresent(false)
    }
  }, [isLoading, section])

  return <RecommendationSection section={'PDP_Compare'} numberOfSkeletons={7} />
}

export default ProductCompare