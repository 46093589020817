import Typography from "Clutch/Atoms/Typography/Typography";
import Button from "Clutch/Molecules/Button/Button";
import EngineFitmentRow from "./engineFitmentFeedbackRow"
import FitmentFeedbackConfirmation from "./engineFitmentFeedbackConfirmation";

import styles from "./engineFitmentFeedback.module.scss";

export const EngineFitmentFeedbackModalButton = ({
  requestFitmentSuccess,
  showFeedback,
  setShowFeedback,
  shouldRender,
  internalRequestEngineFitment
}) => {
  if (!shouldRender) return false;

  return (
    <Button
      className={styles.feedbackButton}
      brand={"primary"}
      size={"small"}
      onClick={() => !requestFitmentSuccess ? setShowFeedback(!showFeedback) : () => {}}
      segmentEvent={{ event: "engineFitmentFeedbackButton" }}
    >
      {requestFitmentSuccess && internalRequestEngineFitment && 
        <Typography size={1}>Engine Fitment Requested</Typography>}
      {!requestFitmentSuccess && internalRequestEngineFitment &&
        <Typography size={1}>Request Engine Fitment</Typography>}
      {!internalRequestEngineFitment && 
        <Typography size={1}>Engine Fitment Feedback</Typography>}
    </Button> 
  );
};

const EngineFitmentFeedbackModalContents = ({
  skuNumber,
  engineFitmentData,
  internalRequestEngineFitment, 
  setRequestFitmentSuccess, 
  isUniversalEngineFitment
}) => {
  if (!engineFitmentData && !internalRequestEngineFitment) return false;

  return (
    <div className={styles.feedbackWrapper}>
      <Typography
        font={"bold"}
        as={"h2"}
        brand={"primary"}
        style={{ marginBottom: "1rem" }}
      >
        Engine Fitment Feedback
      </Typography>
      {(engineFitmentData || []).map((engine) => {
        return (
          <EngineFitmentRow
            skuNumber={skuNumber}
            engine={engine}
            key={`fitment-row-${engine.engineId}`}
          />
        );
      })}
      {internalRequestEngineFitment && 
        <FitmentFeedbackConfirmation
          animation={true}
          requestFitment={true}
          skuNumber={skuNumber}
          setRequestFitmentSuccess={setRequestFitmentSuccess}
          isUniversalEngineFitment={isUniversalEngineFitment}
      />}
    </div>
  );
};

export default EngineFitmentFeedbackModalContents;
