import React from 'react'

import { useProductPageStore } from '../../../contexts/ProductPageStore';

import Typography from 'Clutch/Atoms/Typography'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import TextLink from 'Clutch/Atoms/TextLink'
import styles from './specs.module.scss'
import {ScrollLink} from "../../../components/ScrollLink";

const Specs = ({ toggleAccordion }) => {

  const specs = useProductPageStore(x => x.specs);
  const indexOptionsState = useProductPageStore(x => x.indexOptions);
  const scrollTo = useProductPageStore(x => x.scrollTo);

  if (!specs || specs.length == 0) {
    return false;
  }

  const indexOptions = indexOptionsState.map(indexOption => indexOption.displayName)
  return (
    <ErrorBoundary>
      <table>
        <tbody className={styles.spec_table}>
          {specs
            .filter((item) =>
              item.isKeySpec
            )
            .slice(0, 5)
            .sort((a, b) => {
              return parseInt(a.ordinality) - parseInt(b.ordinality);
            })
            .map((item, i) =>
              <ErrorBoundary key={`spec_sum_${item.title}_${i}`}>
                <tr>
                  <Typography as={'td'} className={styles.spec_title} size={0.875} font={'bold'} tone={'subtle'}>
                    {item.title}
                  </Typography>
                  {item.requiresVariantSelection ? (
                    <TextLink as={'td'} className={styles.spec_value} size={0.875} tone={'subtle'} scrollTo={'pdp_offer'}>
                      {`Select 
                        ${indexOptions.length > 1 ?
                          indexOptions.map(x => x).slice(0, -1).join(', ')
                          + ' and '
                          + indexOptions.map(x => x)[indexOptions.length - 1]
                          :
                          indexOptions[0]
                        }`}
                    </TextLink>
                  ) : (
                    <SpecValue title={item.title} value={item.value} />
                  )}
                </tr>
              </ErrorBoundary>
            )
          }
        </tbody>
      </table>
      <ScrollLink
          id="pdp_specs"
          accordionName="Specs"
          className={styles.see_more}
          size={0.875}
          intent={'action'}
      >
        See more specs
      </ScrollLink>

    </ErrorBoundary>
  )
}
const SpecValue = ({ title, value }) => {
  if (title == "CARB EO#") {
    return (
      <TextLink
        size={0.875}
        className={styles.spec_value}
        underline
        targetBlank
        href={`https://ww2.arb.ca.gov/sites/default/files/aftermarket/devices/eo/${value.toLowerCase()}.pdf`}>
        {value}
      </TextLink>
    )
  }
  return (
    <Typography as={'td'} className={styles.spec_value} size={0.875}>
      {value}
    </Typography>
  )
}

export default Specs