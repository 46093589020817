import React, { useMemo } from 'react'

import { useProductPageStore } from '../../contexts/ProductPageStore'
import classNames from 'classnames'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Accordion from 'Clutch/Molecules/Accordion'
import Typography from 'Clutch/Atoms/Typography'
import Card from 'Clutch/Atoms/Card'
import Divider from 'Clutch/Atoms/Divider'

import Table from './table/table'

import styles from './specs.module.scss'


const Specs = React.memo(({ }) => {

  const isSpecsAccordionOpen = useProductPageStore(x => x.isSpecsAccordionOpen);
  const indexOptionsFromContext = useProductPageStore(x => x.indexOptions);
  const manufacturerPartNumber = useProductPageStore(x => x.manufacturerPartNumber);
  const toggleAccordion = useProductPageStore(x => x.toggleAccordion);
  const brand = useProductPageStore(x => x.brand);
  const specs = useProductPageStore(x => x.specs);

  const keySpecs = useMemo(() => (specs ?? []).filter((item) => item.isKeySpec), [specs])
  const generalSpecs = useMemo(() => (specs ?? []).filter((item) => !item.isKeySpec), [specs])
  const indexOptions = useMemo(() => (indexOptionsFromContext || []).map(indexOption => indexOption.displayName), [indexOptionsFromContext])

  const specsAccordionSummary = (
    <Typography as={'h2'} size={1.25} font={'heading'}>
      {manufacturerPartNumber && brand && brand.brandName ?
        `${brand.brandName} ${manufacturerPartNumber} Specifications` : 'Specifications'}
    </Typography>
  )

  if (!specs || specs.length == 0) {
    return false;
  }

  return (
    <Card fill>
      <Accordion
        intent={'action'}
        iconClosed={'plus'}
        iconOpened={'minus'}
        size={0.875}
        summary={specsAccordionSummary}
        fill
        segmentAccordionName={'pdp specs'}
        defaultOpen={isSpecsAccordionOpen}
        onChange={(isOpen) => toggleAccordion('Specs', isOpen)}>
        <ErrorBoundary>
          { /* The class "attribute-container" is to try to get google to better enhance our page cards */}
          <table className={classNames(styles.specs, "attribute-container")}>
            {keySpecs && keySpecs.length ?
              <ErrorBoundary>
                <thead>
                  <tr>
                    <Typography as={'th'} font={'heading'} className={styles.spec_heading}>
                      KEY SPECS
                    </Typography>
                  </tr>
                </thead>
                <Table specs={keySpecs} indexOptions={indexOptions} />

              </ErrorBoundary> : false}

            {keySpecs && keySpecs.length && generalSpecs && generalSpecs.length ?
              <tbody><tr><td colSpan={'2'}><Divider className={styles.divider} /></td></tr></tbody> : false}

            {generalSpecs && generalSpecs.length ?
              <ErrorBoundary>
                <thead>
                  <tr>
                    <Typography as={'th'} font={'heading'} className={styles.spec_heading}>
                      GENERAL
                    </Typography>
                  </tr>
                </thead>
                <Table specs={generalSpecs} indexOptions={indexOptions} />
              </ErrorBoundary> : false}
          </table>
        </ErrorBoundary>
      </Accordion>
    </Card>
  )
})

export default Specs