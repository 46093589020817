import React, { useContext, useEffect } from 'react'

import Card from 'Clutch/Atoms/Card'
import Divider from 'Clutch/Atoms/Divider'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'

import ColorFinder from 'Clutch/Utilities/ColorFinder'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import { useProductPageStore } from '../../contexts/ProductPageStore'

import styled from 'styled-components'
import { allowedInstallationDetailsAssetCategory, validateDigitalAsset } from '../digitalAssets/digitalAssetUtils'
import DigitalAssets from '../digitalAssets/digitalAssets'
import styles from './installationDetails.module.scss'

const InstallationDetails = React.memo(({ }) => {

  const digitalAssetsFromContext = useProductPageStore((state) => state.digitalAssets);
  const digitalAssets = digitalAssetsFromContext?.filter(digitalAsset => validateDigitalAsset(digitalAsset) && allowedInstallationDetailsAssetCategory(digitalAsset))

  const linkStyling = ColorFinder('action', null, null)
  const tabs = useProductPageStore((state) => state.tabs);
  const installationWarnings = tabs ? tabs.find(tab => tab.key === "Installation Tips" && tab.value && tab.value.length) : null

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
          const element = document.getElementById(hash.substring(1));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, []);

  return (
    <ErrorBoundary>
      {(digitalAssets?.length) || installationWarnings ?
        <><span className={styles.installationDetailsInvisAnchor} id="installation-details" />
        <Card fill dataTestId={'pdp_installation_details'}>
          <Typography size={1.25} font={'heading'}>Installation Details</Typography>
          {digitalAssets?.length ?
            <>
              <Divider className={styles.installation_divider} data-testid={`installation_digital_assets`} />
              <DigitalAssets digitalAssets={digitalAssets} />
            </>
            :
            false
          }
          {installationWarnings ?
            <>
              <Divider className={styles.installation_divider} />
              <div className={styles.installation_warnings_header} data-testid={`installation_warnings_section`}>
                <Svg icon={'Wrench'} size={1} className={styles.installation_warnings_icon}></Svg>
                <Typography font={'bold'} size={0.875}>Installation Tips</Typography>
              </div>
              <div data-testid={`installation_warning_${installationWarnings.key}`}>
                <Typography as={'div'} size={0.875}>
                  <StyledDangerousHtml
                    dangerouslySetInnerHTML={{ __html: `${installationWarnings.value}` }}
                    className={styles.dangerousHtml}
                    linkStyling={linkStyling} />
                </Typography>
              </div>
            </>
            :
            false
          }
        </Card></>
        :
        false
      }
    </ErrorBoundary>
  )
})

const StyledDangerousHtml = styled.div`
  a {
    color: ${(props) => props.linkStyling.color};
    &:hover {
      color: ${(props) => props.linkStyling.hoverColor};
    }
  }
`

export default InstallationDetails
