const fitmentDisplayTypes = {
  selectVehicle: "selectVehicle",
  selectEngine: "selectEngine",
  selectVehicleAndEngine: "selectVehicleAndEngine",
  direct: "direct",
  fits: "fits",
  universal: "universal",
  doesntFit: "doesntFit",
  needsMoreInfo: "needsMoreInfo",
};

const availableFitmentTypes = {
  ymmOnly: "YMMOnly",
  engineOnly: "EngineOnly",
  engineandYmm: "EngineAndYMM",
};

const convertFitmentTypeToFitmentDisplayType = (fitmentType) => {
  switch (fitmentType) {
    case availableFitmentTypes.ymmOnly:
      return fitmentDisplayTypes.selectVehicle;
    case availableFitmentTypes.engineOnly:
      return fitmentDisplayTypes.selectEngine;
    case availableFitmentTypes.engineandYmm:
      return fitmentDisplayTypes.selectVehicleAndEngine;
    default:
      return fitmentDisplayTypes.selectVehicle;
  }
};

const determineFitmentDisplayType = (
  fitmentType,
  customerProjects,
  selectedCustomerProject,
  vehicleBaseFitment,
  raceTypeFitment,
  engineFitment
) => {
  if (!customerProjects || !customerProjects.length) {
    return convertFitmentTypeToFitmentDisplayType(fitmentType);
  }

  const selectedVehicleBases = customerProjects.filter(
    (vehicle) =>
      vehicle &&
      selectedCustomerProject?.vehicleBaseId &&
      vehicle.vehicleBaseId === selectedCustomerProject?.vehicleBaseId
  );
  const selectedRaceTypes = customerProjects.filter(
    (vehicle) =>
      vehicle &&
      selectedCustomerProject?.raceTypeId &&
      vehicle.raceTypeId === selectedCustomerProject?.raceTypeId
  );

  const selectedEngines = customerProjects.filter(
    (vehicle) =>
      vehicle &&
      selectedCustomerProject?.engineDisplayName &&
      vehicle.engineDisplayName === selectedCustomerProject?.engineDisplayName
  );

  if (
    !(selectedVehicleBases || []).length &&
    !(selectedRaceTypes || []).length &&
    !(selectedEngines || []).length
  )
    return convertFitmentTypeToFitmentDisplayType(fitmentType);

  if (
    selectedVehicleBases &&
    selectedVehicleBases.length &&
    vehicleBaseFitment &&
    vehicleBaseFitment.length
  ) {
    const selectedVehicleBase = selectedVehicleBases.find(x => x.projectId === selectedCustomerProject?.projectId) ?? selectedVehicleBases[0];
    const fitmentInfoList = vehicleBaseFitment.filter(
      (vehicle) => vehicle.key == selectedVehicleBase.projectId
    );
    return determineTypeOfFitmentFromFitmentInfo(fitmentInfoList);
  } else if (
    selectedRaceTypes &&
    selectedRaceTypes.length &&
    raceTypeFitment &&
    raceTypeFitment.length
  ) {
    const selectedRaceType = selectedRaceTypes.find(x => x.projectId === selectedCustomerProject?.projectId) ?? selectedRaceTypes[0];
    const fitmentInfoList = raceTypeFitment.filter(
      (vehicle) => vehicle.key == selectedRaceType.projectId
    );
    return determineTypeOfFitmentFromFitmentInfo(fitmentInfoList);
  } else if (
    selectedEngines &&
    selectedEngines.length &&
    engineFitment &&
    engineFitment.length
  ) {
    const selectedEngine = selectedEngines.find(x => x.projectId === selectedCustomerProject?.projectId) ?? selectedEngines[0];
    const fitmentInfoList = engineFitment.filter(
      (vehicle) => vehicle.key == selectedEngine.projectId
    );
    return determineTypeOfFitmentFromFitmentInfo(fitmentInfoList);
  } else return null;
};

const determineTypeOfFitmentFromFitmentInfo = (fitmentInfoList) => {
  if (!fitmentInfoList || !fitmentInfoList.length) return null;

  const fitmentInfo = fitmentInfoList[0].value;

  let result = fitmentDisplayTypes.doesntFit;

  // Order of these conditionals matter
  if (fitmentInfo.isDirectFit || fitmentInfo.isDrivelineFit)
    result = fitmentDisplayTypes.direct;
  else if (fitmentInfo.isUniversalFit) result = fitmentDisplayTypes.universal;
  else if (fitmentInfo.fits && !fitmentInfo.needsMoreInfo)
    result = fitmentDisplayTypes.fits;
  else if (!fitmentInfo.fits && fitmentInfo.needsMoreInfo)
    result = fitmentDisplayTypes.needsMoreInfo;
  return result;
};

export { determineFitmentDisplayType, fitmentDisplayTypes };
