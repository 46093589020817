import { useState } from "react";

import Typography from "Clutch/Atoms/Typography/Typography";
import Svg from "Clutch/Atoms/Svg";
import FitmentFeedbackConfirmation from "./engineFitmentFeedbackConfirmation";

import styles from "./engineFitmentFeedback.module.scss";

const EngineFitmentRow = ({ skuNumber, engine }) => {
  const [animation, setAnimation] = useState(false);
  const [expander, setExpander] = useState(false);

  if(!skuNumber || !engine) return false

  const engineName = engine.engineDisplayName
  const shouldRenderSvg = engine.vehicleBases?.length > 0 || engine.raceTypes?.length > 0 || engine.markets?.length > 0;

  return (
    <div
      key={`fitment_feedback_${engine.engineId}`}
      className={styles.rowWrapper}
    >
      <div className={styles.svgWrapper} expander={expander.toString()}>
        {shouldRenderSvg ?
          <Svg
            icon="plus"
            size={0.75}
            onClick={() => setExpander(!expander)}
            className={styles.svgClass}
            title={"See Vehicles"}
          /> : <div style={{marginRight:'12px'}}></div>}
        <div
          className={styles.heading}
          onClick={() => setAnimation(!animation)}
        >
          <Typography font={"bold"}>{engineName}</Typography>
          <Typography intent={"action"} font={"bold"} className={styles.flag}>
            Flag Fitment For {engineName}
          </Typography>
        </div>
      </div>
      <FitmentFeedbackConfirmation
        fitmentRow={engineName}
        animation={animation}
        closeFunc={() => setAnimation(false)}
        skuNumber={skuNumber}
        engineId={engine.engineId}
      />
      <EngineInfoExpander
        skuNumber={skuNumber}
        engine={engine}
        engineName={engineName}
        expander={expander}
      />
    </div>
  );
};

const EngineInfoExpander = ({ skuNumber, engine, engineName, expander }) => {
  if(!engine.vehicleBases && !engine.raceTypes && !engine.markets) 
    return false;

  return (
    <div className={styles.expander} expander={expander.toString()}>
      {engine.vehicleBases && <Typography className={styles.heading} style={{fontStyle: 'italic', fontWeight: 'bold'}}>YMM</Typography>}
      {(engine.vehicleBases || []).map((vehicleBase) => {
        return (
          <VehicleFitmentRow
            skuNumber={skuNumber}
            engine={engine}
            engineName={engineName}
            vehicleBase={vehicleBase}
            key={`fitment_feedback_${engine.engineId}_${vehicleBase.vehicleBaseId}`}
          />
        );
      })}
      {engine.raceTypes && <Typography className={styles.heading} style={{fontStyle: 'italic', fontWeight: 'bold'}}>Race Types</Typography>}
      {(engine.raceTypes || []).map((raceType) => {
        return (
          <RaceTypeFitmentRow
            skuNumber={skuNumber}
            engine={engine}
            engineName={engineName}
            raceType={raceType}
            key={`fitment_feedback_${engine.engineId}_${raceType.raceTypeName}`}
          />
        );
      })}
      {engine.markets && <Typography className={styles.heading} style={{fontStyle: 'italic', fontWeight: 'bold'}}>Markets</Typography>}
      {(engine.markets || []).map((market) => {
        return (
          <MarketFitmentRow
            skuNumber={skuNumber}
            engine={engine}
            engineName={engineName}
            market={market}
            key={`fitment_feedback_${engine.engineId}_${market.marketName}`}
          />
        );
      })}
    </div>
  );
};

const VehicleFitmentRow = ({ skuNumber, engine, engineName, vehicleBase }) => {
  const [animation, setAnimation] = useState(false);
  const vehicleBaseName = `${vehicleBase.year} ${vehicleBase.make} ${vehicleBase.model}`;

  return (
    <>
      <div
        className={styles.subHeading}
        onClick={() => setAnimation(!animation)}
      >
        <Typography as="label">{vehicleBaseName}</Typography>
        <Typography intent="action" className={styles.flag}>
          Flag Fitment For {engineName} + YMM
        </Typography>
      </div>
      <FitmentFeedbackConfirmation
        animation={animation}
        fitmentRow={`${engineName} + ${vehicleBaseName}`}
        closeFunc={() => setAnimation(false)}
        skuNumber={skuNumber}
        engineId={engine.engineId}
      />
    </>
  );
};

const RaceTypeFitmentRow = ({ skuNumber, engine, engineName, raceType }) => {
  const [animation, setAnimation] = useState(false);

  return (
    <>
      <div onClick={() => setAnimation(!animation)}>
        <div
          className={styles.subHeading}
          id={`subheading_${engine.engineId}_${raceType.raceTypeName}`}
        >
          <Typography as="label">{raceType.raceTypeName}</Typography>
          <Typography intent="action" className={styles.flag}>
            Flag Fitment For {engineName} + RaceType
          </Typography>
        </div>
      </div>
      <FitmentFeedbackConfirmation
        animation={animation}
        skuNumber={skuNumber}
        fitmentRow={`${engineName} + ${raceType.raceTypeName}`}
        closeFunc={() => setAnimation(false)}
        engineId={engine.engineId}
      />
    </>
  );
};

const MarketFitmentRow = ({ skuNumber, engine, engineName, market }) => {
  const [animation, setAnimation] = useState(false);

  return (
    <>
      <div onClick={() => setAnimation(!animation)}>
        <div
          className={styles.subHeading}
          id={`subheading_${engine.engineId}_${market.marketName}`}
        >
          <Typography as="label">{market.marketName}</Typography>
          <Typography intent="action" className={styles.flag}>
            Flag Fitment For {engineName} + Market
          </Typography>
        </div>
      </div>
      <FitmentFeedbackConfirmation
        animation={animation}
        skuNumber={skuNumber}
        fitmentRow={`${engineName} + ${market.marketName}`}
        closeFunc={() => setAnimation(false)}
        engineId={engine.engineId}
      />
    </>
  );
};

export default EngineFitmentRow;
