import React, { useState, useEffect } from 'react'
import Image from 'next/image'

import { useProductPageStore } from '../../contexts/ProductPageStore';

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Divider from 'Clutch/Atoms/Divider'
import Drawer from 'Clutch/Molecules/NewDrawer/Drawer'
import Area from 'Clutch/Molecules/Area'
import Card from 'Clutch/Atoms/Card'

import styles from './whatsInTheKitDrawer.module.scss'

const WhatsInTheKitDrawer = ({ }) => {

  const [kitComponents, updateKitComponents] = useState([])
  const isWhatsInTheKitDrawerOpen = useProductPageStore(x => x.isWhatsInTheKitDrawerOpen);
  const kitComponentsFromContext = useProductPageStore(x => x.kitComponents);
  const kitVariantComponents = useProductPageStore(x => x.kitVariantComponents);
  const hideWhatsInTheKitDrawer = useProductPageStore(x => x.hideWhatsInTheKitDrawer);

  useEffect(() => {
    const clonedKitComponents = JSON.parse(JSON.stringify(kitComponentsFromContext || []));
    let skuBaseKitComponents = [];

    //de dup sku bases but combine quantities
    (clonedKitComponents || []).forEach(x =>
      skuBaseKitComponents.filter(y => y.skuBaseNumber === x.skuBaseNumber)[0] ?
        skuBaseKitComponents.filter(y => y.skuBaseNumber === x.skuBaseNumber)[0].purchaseQuantity += x.purchaseQuantity :
        skuBaseKitComponents.push(x))

    const skuVariantKitComponents = kitVariantComponents || []

    const kitComponents = skuBaseKitComponents.concat(skuVariantKitComponents)
      .sort((a, b) => {
        return parseInt(a.ordinality) - parseInt(b.ordinality);
      })

    updateKitComponents(kitComponents)
  }, [kitComponentsFromContext, kitVariantComponents])
  
  return (
    <ErrorBoundary>
      <Drawer
        location={'right'}
        isOpen={isWhatsInTheKitDrawerOpen}
        onClose={() => hideWhatsInTheKitDrawer()}
        portalId={'witk_drawer'}
        title={'Kit Components'}
        basePanelLayer={0}
      >
        {kitComponents.map((kitComponent, i) =>
          <ErrorBoundary key={`kit_component_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`}>
            {i == 0 ? false : <Divider className={styles.component_divider} />}
            <div data-testid={`kit_component_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`} className={styles.kit_component}>
              <div className={styles.component_image}>
                {kitComponent.productPageUrl ?
                  <TextLink targetBlank href={kitComponent.productPageUrl}>
                    <Image
                      height={64}
                      width={64}
                      src={kitComponent.imageUrl ? `https:${kitComponent.imageUrl}` : 'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'}
                      data-testid={`kit_component_image_link_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`} />
                  </TextLink>
                  :
                  <Image
                    height={64}
                    width={64}
                    src={`https:${kitComponent.imageUrl}`}
                    data-testid={`kit_component_image_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`} />
                }
              </div>
              <div className={styles.component_right_side}>
                <Typography size={0.625}>{`Part #: ${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}`}</Typography>
                {kitComponent.productPageUrl ?
                  <TextLink
                    targetBlank
                    size={1}
                    className={styles.component_name}
                    href={kitComponent.productPageUrl}
                    data-testid={`kit_component_textlink_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`}>
                    {kitComponent.skuVariantName ? kitComponent.skuVariantName : kitComponent.skuBaseName}
                  </TextLink>
                  :
                  <Typography
                    targetBlank
                    size={1}
                    className={styles.component_name}
                    href={kitComponent.productPageUrl}
                    data-testid={`kit_component_typography_${kitComponent.skuVariantNumber ? kitComponent.skuVariantNumber : kitComponent.skuBaseNumber}_${i}`}>
                    {kitComponent.skuVariantName ? kitComponent.skuVariantName : kitComponent.skuBaseName}
                  </Typography>
                }
                <Typography size={0.875} font={'heading'}>{`Qty: ${kitComponent.purchaseQuantity}`}</Typography>                 
              </div>
            </div>
            {
              kitComponent.availability == 'NotAvailable' ? <Area intent={'negative'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is currently Out of Stock.`}</Typography></Area> :
              kitComponent.availability == 'Discontinued' ? <Area intent={'negative'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Discontinued.`}</Typography></Area> :
              kitComponent.availability == 'Backorderable' ? <Area intent={'warning'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Backordered.`}</Typography></Area> :
              kitComponent.availability == 'FactoryShipped' ? <Area intent={'warning'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Factory Shipped.`}</Typography></Area> :
              kitComponent.availability == 'MadeToOrder' ? <Area intent={'warning'} className={styles.component_divider_bottom}><Typography size={0.875} font={'bold'}>{`This part is Made to Order.`}</Typography></Area> :
              false
            }
          </ErrorBoundary>
        )}
      </Drawer>
    </ErrorBoundary>
  )
}

export default WhatsInTheKitDrawer