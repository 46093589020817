import React from 'react';
import {useScroll} from '../hooks/useScroll';
import TextLink from 'Clutch/Atoms/TextLink'
import {useProductPageStore} from "../contexts/ProductPageStore";

export const ScrollLink = ({
                               id,
                               accordionName,
                               offset,
                               className,
                               children,
                               shouldOpenAccordion = true,
                               ...props
                           }) => {
    const {scrollToElement, scrollToAccordionElement} = useScroll();
    const toggleAccordion = useProductPageStore(state => state.toggleAccordion);
    const scrollTo = useProductPageStore(state => state.scrollTo);

    const handleClick = (e) => {
        e.preventDefault();

        if (accordionName) {

            if(shouldOpenAccordion) {
                toggleAccordion(accordionName, true);
            }

            scrollTo(id, accordionName, true, shouldOpenAccordion);

            scrollToAccordionElement(id, accordionName, {offset});
        } else {
            scrollToElement(id, {offset});
        }
    };

    return (
        <TextLink
            href={`#${id}`}
            onClick={handleClick}
            className={className}
            {...props}
        >
            {children}
        </TextLink>
    );
};