import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react'
import Image from 'next/image'
import ClassNames from 'classnames'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Button from 'Clutch/Molecules/Button'
import Svg from 'Clutch/Atoms/Svg'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';

import { useProductPageStore } from '../../contexts/ProductPageStore'
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext'

import { GetUserGuid } from 'Clutch/Utilities/CookieUtilties/UserCookieUtilities'

import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import { validateDigitalAsset } from '../digitalAssets/digitalAssetUtils'
import styles from './stickyHeader.module.scss'
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import { useInViewStore } from "@speedwaymotors/clutch/Hooks/useIntersectionObserver";

const StickyHeaderContent = ({ }) => {
  const segmentCategorization = useProductPageStore(x => x.segmentCategorization);
  const quantityRequested = useProductPageStore(x => x.quantityRequested);
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const title = useProductPageStore(x => x.title);
  const images = useProductPageStore(x => x.images);
  const breadcrumb = useProductPageStore(x => x.breadcrumb);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const brand = useProductPageStore(x => x.brand);
  const retailLowPrice = useProductPageStore(x => x.retailLowPrice);
  const productPageUrl = useProductPageStore(x => x.productPageUrl);
  const availabilityEnum = useProductPageStore(x => x.availabilityEnum);
  const reviewCount = useProductPageStore(x => x.reviewCount);
  const rating = useProductPageStore(x => x.rating);
  const superMarketAffinity = useProductPageStore(x => x.superMarketAffinity);
  const digitalAssetsFromContext = useProductPageStore(x => x.digitalAssets);
  const partNumber = useProductPageStore(x => x.partNumber);
  const isPurchaseable = useProductPageStore(x => x.isPurchaseable);
  const price = useProductPageStore(x => x.price);
  const unit = useProductPageStore(x => x.unit);
  const isIndexOptionKit = useProductPageStore(x => x.isIndexOptionKit);
  const indexOptions = useProductPageStore(x => x.indexOptions);
  const errorIndexOptions = useProductPageStore(x => x.errorIndexOptions);
  const isCompareToolPresent = useProductPageStore(x => x.isCompareToolPresent);
  const scrollTo = useProductPageStore(x => x.scrollTo);
  const tabs = useProductPageStore(x => x.tabs);
  const specs = useProductPageStore(x => x.specs);
  const videos = useProductPageStore(x => x.videos);
  const articles = useProductPageStore(x => x.articles);
  const inViewData = useInViewStore(x => x.inViewData)

  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  const compareEnabled = useFeatureFlag('PDP_Compare_Tool')
  const compareBoostEnabled = useLazyFeatureFlag(brand?.brandName && !brand?.isHouseBrand ? "PDP_Compare_Tool_Boosting" : "placeholderdontdelete")

  const [highlightedSection, setHighlightedSection] = useState('')

  let isPowerReviewsImageCarouselOpen = document.getElementsByClassName("pr-media-modal")?.length > 0;

  useEffect(() => {
    if (inViewData.summaryInView ) {
      setHighlightedSection('summary')
    } else if (inViewData.compareRefInView) {
      setHighlightedSection('compare')
    } else if (inViewData.summaryRecommendationsInView || inViewData.aplusCardContentInView) {
      setHighlightedSection('summary')
    } else if (inViewData.detailsClosedInView || inViewData.detailsOpenInView) {
      setHighlightedSection('details')
    } else if (inViewData.guidesDocsInView || inViewData.carbProp65InView) {
      setHighlightedSection('details_guides_docs')
    } else if (inViewData.specsInView) {
      setHighlightedSection('specs')
    } else if (inViewData.ratingsInView) {
      setHighlightedSection('ratings')
    } else if (inViewData.qnaInView) {
      setHighlightedSection('qna')
    } else if (inViewData.videosInView) {
      setHighlightedSection('videos')
    } else {
      setHighlightedSection('')
    }
  }, [inViewData]);

  const item = {
    skuVariantNumber: skuVariantNumber,
    skuBaseNumber: skuBaseNumber,
    quantityRequested: parseInt(quantityRequested),
    title: title,
    images: images,
    segmentCategory: segmentCategorization,
    brand: brand?.brandName,
    price: retailLowPrice,
    url: productPageUrl,
    availabilityEnum: availabilityEnum,
    rating: rating,
    reviewCount: reviewCount,
    superMarketAffinity: superMarketAffinity,
  }

  const breadcrumbItem = useMemo(() => (breadcrumb || []).sort((a, b) => {
    return parseInt(b.ordinality) - parseInt(a.ordinality);
  }).filter(item => item.url && item.url != '/')[0], [breadcrumb])

  const digitalAssets = digitalAssetsFromContext?.filter(digitalAsset => validateDigitalAsset(digitalAsset))

  const sectionLinkClass = compareEnabled ? styles.section_link_crowded : styles.section_link

  if (isPowerReviewsImageCarouselOpen) {
    return false;
  }

  return (
    <ErrorBoundary>
      <span className={ClassNames(styles.sticky_header, isPowerReviewsImageCarouselOpen && styles.hide_sticky_header)}>
        <div className={styles.upper_section}>
          <div className={styles.product_header}>
            <Image
              src={`https:${(images || []).length ? images[0] : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'}`}
              width={48}
              height={48}
              alt={''}
            />
            <div className={styles.product_header_text}>
              <Typography size={0.875} >
                {title}
              </Typography>
              {partNumber ?
                <Typography size={0.75} tone={'subtle'} inline>
                  {`Part # ${partNumber} `}
                </Typography> : false}
              {brand && brand.brandName ?
                <Fragment>
                  <Typography size={0.75} tone={'subtle'} inline>
                    {'by '}
                  </Typography>
                  {brand.brandLinkUrl ?
                    <TextLink size={0.75} href={brand.brandLinkUrl} inline underline data-testid={'sh_brandLink'}>
                      {brand.brandName}
                    </TextLink> :
                    <Typography size={0.75} inline data-testid={'sh_brandText'}>
                      {brand.brandName}
                    </Typography>
                  }
                </Fragment> : false
              }
            </div>
          </div>

          <div className={styles.header_right_side}>
            {isPurchaseable ?
              <ErrorBoundary>
                {price ?
                  <div className={styles.pricing}>
                    <Typography inline font={'bold'} size={1.25}>
                      {price}
                    </Typography>
                    {unit ?
                      <Typography className={styles.superscript} inline tone={'subtle'} size={0.75}>
                        {' ' + unit}
                      </Typography>
                      :
                      false}
                  </div>
                  :
                  false}
                {skuVariantNumber ?
                  <Button
                    inline
                    intent={'positive'}
                    size={'small'}
                    className={styles.sticky_header_button}
                    text={'Add to Cart'}
                    data-testid={'sh_atc'}
                    segmentEvent={{
                      event: 'Product Add Started',
                      properties: {
                        cart_id: GetUserGuid(),
                        product_id: skuBaseNumber,
                        sku: skuBaseNumber,
                        name: title,
                        variant: skuVariantNumber,
                        quantity: quantityRequested,
                        brand: brand ? brand.brandName || '' : '',
                        category: segmentCategorization,
                        price: retailLowPrice,
                        url: productPageUrl,
                        image_url: (images || []).length ? `https:${images[0]}` : "",
                        availability: availabilityEnum,
                        rating: reviewCount ? rating : 0,
                        reviews: reviewCount,
                        audience_affinity: getSuperMarketAffinity({superMarketAffinity})
                      }
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      isIndexOptionKit
                        ? addToCartExperienceContext.showKitDetailsDrawer()
                        : addToCartExperienceContext.addToCart([item])
                    }}
                  />
                  :
                  indexOptions && indexOptions.length ?
                    <Button
                      inline
                      intent={'action'}
                      size={'small'}
                      className={styles.sticky_header_button}
                      text={`
                        Select 
                        ${indexOptions.length > 1 ?
                          indexOptions.map(x => x.displayName).slice(0, -1).join(', ')
                          + ' and '
                          + indexOptions.map(x => x.displayName)[indexOptions.length - 1]
                          :
                          indexOptions[0].displayName
                        }
                      `}
                      data-testid={'sh_select_options'}
                      segmentEvent={createClickedSegmentEvent('SelectIndexOptions')}
                      onClick={(e) => {
                        e.preventDefault()
                        errorIndexOptions()
                      }} />
                    :
                    false
                }
                <Svg icon={'heart'}
                  size={2}
                  data-testid={'sh_save_to_list'}
                  onClick={(e) => {
                    e.preventDefault()
                    isIndexOptionKit
                      ? addToCartExperienceContext.showKitDetailsDrawer({ list: true })
                      : !skuVariantNumber
                        ? errorIndexOptions()
                        : addToCartExperienceContext.showListDrawer([item])
                  }} />
              </ErrorBoundary>
              :
              <ErrorBoundary>
                <Typography inline size={1.25} className={ClassNames(styles.pricing, styles.shop_all)}>
                  Product discontinued
                </Typography>
                <Button
                  inline
                  intent={'action'}
                  size={'small'}
                  href={breadcrumbItem ? breadcrumbItem.url : '/shop/all'}
                  text={breadcrumbItem ? `Shop all ${breadcrumbItem.displayText}` : 'Shop all Categories'}
                  data-testid={'sh_shop_all'}
                  segmentEvent={createClickedSegmentEvent('ShopAll')}
                  />
              </ErrorBoundary>
            }
          </div>
        </div>
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'top' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'top' && styles.selected_section)}
          scrollTo={'__next'}>
          Top
        </TextLink>
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'summary' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'summary' && styles.selected_section)}
          scrollTo={'pdp_summary'}>
          Summary
        </TextLink>
        {compareBoostEnabled && compareEnabled && isCompareToolPresent ? 
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'compare' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'compare' && styles.selected_section)}
            onClick={() => scrollTo('pdp_productcompare')}>
            {'Compare Similar Products'}
          </TextLink> : false
        }
        {tabs?.length || digitalAssets?.length ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'details' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'details' && styles.selected_section)}
            onClick={() => { scrollTo('pdp_details', 'Details') }}>
            Details
          </TextLink> : false}
        {digitalAssets?.length ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'details_guides_docs' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'details_guides_docs' && styles.selected_section)}
            onClick={() => { scrollTo('pdp_details_guides_docs', 'Details', true) }}>
            {'Guides & Documents'}
          </TextLink> : false}
        {specs && specs.length ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'specs' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'specs' && styles.selected_section)}
            onClick={() => { scrollTo('pdp_specs', 'Specs') }}>
            Specifications
          </TextLink> : false}
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'ratings' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'ratings' && styles.selected_section)}
          onClick={() => { scrollTo('pdp_ratings', 'RatingsAndReviews') }}>
          {'Ratings & Reviews'}
        </TextLink>
        <TextLink
          inline
          size={0.875}
          font={highlightedSection == 'qna' ? 'bold' : 'regular'}
          className={ClassNames(sectionLinkClass, highlightedSection == 'qna' && styles.selected_section)}
          onClick={() => { scrollTo('pdp_qna', 'QuestionsAndAnswers') }}>
          {'Questions & Answers'}
        </TextLink>
        {(videos && videos.length)
          || (articles && articles.length) ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'videos' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'videos' && styles.selected_section)}
            onClick={() => { scrollTo('pdp_videosAndArticles', 'VideosAndArticles') }}>
            {'Videos & Articles'}
          </TextLink> : false}
        {compareEnabled && !compareBoostEnabled && isCompareToolPresent ?
          <TextLink
            inline
            size={0.875}
            font={highlightedSection == 'compare' ? 'bold' : 'regular'}
            className={ClassNames(sectionLinkClass, highlightedSection == 'compare' && styles.selected_section)}
            onClick={() => { scrollTo('pdp_productcompare') }}>
            {'Compare Similar Products'}
          </TextLink> : false}
      </span>
    </ErrorBoundary >
  )
}

export default StickyHeaderContent