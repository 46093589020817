export const allowedInstallationDetailsAssetCategory = (digitalAsset) => {
  return (digitalAsset.category === "InstructionSheet" || digitalAsset.category === "BuyersGuide");
}

export const validateDigitalAsset = (digitalAsset) => {

  return digitalAsset.displayTitle 
    && ((digitalAsset.assetType === 'Image'
      && digitalAsset.associatedImages
      && digitalAsset.associatedImages.length
      && digitalAsset.associatedImages.find(image => image.imageType === "Optimized")
      && digitalAsset.associatedImages.find(image => image.imageType === "Optimized").imageUrl) 
    || (digitalAsset.assetType === 'Document'
      && digitalAsset.associatedDocument
      && digitalAsset.associatedDocument.documentUrl) 
    || (digitalAsset.assetType === 'Url' 
      && digitalAsset.assetName))
}