import { useEffect, useContext } from 'react';
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext';
import { useProductPageStore } from '../../contexts/ProductPageStore';

const KitDetailsDrawerHandler = () => {
  const isIndexOptionKit = useProductPageStore(x => x.isIndexOptionKit);
  const kitComponents = useProductPageStore(x => x.kitComponents);
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const quantityRequested = useProductPageStore(x => x.quantityRequested);
  const title = useProductPageStore(x => x.title);
  const images = useProductPageStore(x => x.images);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const brand = useProductPageStore(x => x.brand);
  const priceDecimal = useProductPageStore(x => x.priceDecimal);
  const segmentCategorization = useProductPageStore(x => x.segmentCategorization);
  const productPageUrl = useProductPageStore(x => x.productPageUrl);
  const availabilityEnum = useProductPageStore(x => x.availabilityEnum);
  const rating = useProductPageStore(x => x.rating);
  const reviewCount = useProductPageStore(x => x.reviewCount);
  const superMarketAffinity = useProductPageStore(x => x.superMarketAffinity);
  const isLazyDataHydrated = useProductPageStore(x => x.isLazyDataHydrated);
  
  const addToCartExperienceContext = useContext(AddToCartExperienceContext);

  useEffect(() => {
    if (isIndexOptionKit) {
      addToCartExperienceContext.populateKitDetailsDrawer(
        kitComponents,
        skuVariantNumber,
        quantityRequested,
        title,
        images,
        skuBaseNumber,
        brand?.brandName,
        priceDecimal,
        segmentCategorization,
        productPageUrl,
        availabilityEnum,
        rating,
        reviewCount,
        superMarketAffinity
      );
    }
  }, [isIndexOptionKit, quantityRequested, isLazyDataHydrated]);

  return null;
};

export default KitDetailsDrawerHandler;
