import groupBy from '../../utils/groupBy'


const combineImagesAndVideos = (images, videos, spinImages, defaultMissingImage = true, customerImages = false) => {

  images = images && images.length ? images : defaultMissingImage ? ['//content.speedwaymotors.com/OtherImages/missingimage2.jpg'] : []
  videos = videos && videos.length ? videos : [] //['dQw4w9WgXcQ']

  const imagesWithData = images.map(image => {
    return {
      'media': image,
      'isImage': true,
      'customerImage': customerImages
    }
  })

  const videosWithData = videos.map(video => {
    return {
      'media': video,
      'isVideo': true,
      'thumbnailImage': `https://content.speedwaymotors.com/OtherImages/missingimage2.jpg`
    }
  })

  const spinImagesEnabled = false
  const spinGroups = groupBy((spinImages || []), 'imageOrder')
  const spinGroupKeys = spinGroups ? Object.keys(spinGroups) : []
  const spinImagesWithData = spinImagesEnabled ? spinGroupKeys.sort((a, b) => {return parseInt(a.imageOrder) - parseInt(b.imageOrder)}).map(key => {
    console.log()
    return {
      'media': spinGroups[key][0].imageSrcUrl,
      'isImage': true,
      'spinImages': spinGroups[key]
        .sort((a, b) => { return parseInt(a.imageSpinOrder) - parseInt(b.imageSpinOrder)})
        .map(spinImage => `https:${spinImage.imageSrcUrl}`)
    }
  }) : []



  const combinedMedia = imagesWithData.length > 4 ?
    [].concat(imagesWithData.slice(0, 4)).concat(videosWithData).concat(spinImagesWithData).concat(imagesWithData.slice(4)) :
    [].concat(imagesWithData).concat(videosWithData)

  return combinedMedia
}

async function getThumbnailImageForVideos(media) {

  const promises = media
    .filter(item => item.isVideo)
    .map(async item => {
      const response = await window.fetch(`https://noembed.com/embed?url=${`https://www.youtube.com/watch?v=${item.media}&maxwidth=92&maxheight=72&nowrap=on`}`);
      const data = await response.json();
      if (data.thumbnail_url) {
        const position = media.map(x => x.media).indexOf(item.media);
        return {
          'position': position,
          'thumbnailImage': data.thumbnail_url.replace('/hqdefault.jpg', '/mqdefault.jpg')
        }
      }
    })

  return Promise.all(promises).then((responses) => {
    const newMediaList = media;

    (responses || []).filter(response => response).map(response => {
        newMediaList[response.position] = {
          'media': media[response.position].media,
          'isVideo': media[response.position].isVideo,
          'thumbnailImage': response.thumbnailImage
        }
    })
    return newMediaList
  });
}


export { combineImagesAndVideos, getThumbnailImageForVideos }