import {useCallback, useRef, useEffect, useState} from 'react';

const SCROLL_DURATION = 300;
const DEFAULT_OFFSET = 100;
const POSITION_CHECK_INTERVAL = 50;
const MAX_POSITION_CHECKS = 100;
const isIOS = typeof window !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const useScroll = () => {
    const scrollTimeout = useRef();
    const positionCheckInterval = useRef();
    const lastKnownPosition = useRef(null);
    const [scrollState, setScrollState] = useState({
        active: false,
        id: null
    });

    const resetScrollState = useCallback(() => {
        if (positionCheckInterval.current) {
            clearInterval(positionCheckInterval.current);
        }
        setScrollState(prev => ({
            ...prev,
            active: false,
            id: null
        }));
        lastKnownPosition.current = null;
    }, []);

    const cancelScroll = useCallback(() => {
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }
        resetScrollState();
    }, [resetScrollState]);

    useEffect(() => {
        return cancelScroll;
    }, [cancelScroll]);

    const getElementPosition = (element, offset) => {
        const rect = element.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop - offset;
    };

    const monitorPositionChanges = useCallback((element, targetOffset, onComplete) => {
        let checkCount = 0;
        lastKnownPosition.current = getElementPosition(element, targetOffset);

        positionCheckInterval.current = setInterval(() => {
            checkCount++;
            const currentPosition = getElementPosition(element, targetOffset);

            if (Math.abs(currentPosition - lastKnownPosition.current) > 5) {
                window.scrollTo({
                    top: currentPosition,
                    behavior: 'auto'
                });
                lastKnownPosition.current = currentPosition;
                checkCount = 0;
            }

            if (checkCount >= MAX_POSITION_CHECKS) {
                clearInterval(positionCheckInterval.current);
                onComplete?.();
                resetScrollState();
            }
        }, POSITION_CHECK_INTERVAL);
    }, [resetScrollState]);

    const scrollToElement = useCallback(async (id, options = {}) => {
        const {
            offset = DEFAULT_OFFSET,
            duration = SCROLL_DURATION,
            onComplete
        } = options;

        if (scrollState.active) return;

        const element = document.getElementById(id);
        if (!element) {
            console.warn(`Element with id "${id}" not found`);
            return;
        }

        try {
            setScrollState(prev => ({...prev, active: true, id}));

            const initialPosition = getElementPosition(element, offset);

            if (isIOS) {
                document.documentElement.style.setProperty('--scroll-offset', `${-initialPosition}px`);
                document.body.style.transform = `translateY(${-initialPosition}px)`;

                setTimeout(() => {
                    document.body.style.transform = '';
                    window.scrollTo(0, initialPosition);

                    monitorPositionChanges(element, offset, onComplete);
                }, duration);
            } else {
                window.scrollTo({
                    top: initialPosition,
                    behavior: 'smooth'
                });

                scrollTimeout.current = setTimeout(() => {
                    monitorPositionChanges(element, offset, onComplete);
                }, duration);
            }
        } catch (error) {
            console.error('Scroll error:', error);
            resetScrollState();
        }
    }, [scrollState.active, resetScrollState, monitorPositionChanges]);

    const scrollToAccordionElement = useCallback(async (id, accordionName, options = {}) => {
        if (scrollState.active) return;

        try {
            setScrollState(prev => ({
                ...prev,
                active: true,
                id
            }));

            await new Promise(resolve => setTimeout(resolve, 150));
            await scrollToElement(id, options);
        } catch (error) {
            console.error('Accordion scroll error:', error);
            resetScrollState();
        }
    }, [scrollState.active, scrollToElement, resetScrollState]);

    return {
        scrollToElement,
        scrollToAccordionElement,
        cancelScroll,
        isScrolling: scrollState.active
    };
};